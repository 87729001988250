<template>
    <div class="row">
        <!-- table -->
        <div class="col-12">
            <b-card header="Manager Meson Credit Deposit">
                <div class="pr-3 pb-3 pl-3">
                    <b-row class="mb-3">
                        <b-col>
                            <VDropdown>
                                <b-button variant="secondary" class="mr-3">
                                    <feather-icon icon="SearchIcon" class="mr-2" size="15" />
                                    <span class="align-middle">Search-items</span>
                                </b-button>
                                <!-- This will be the content of the popover -->
                                <template #popper>
                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <b-form-input placeholder="user id" v-model="queryCondition.userId" />
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <v-select
                                                id="status"
                                                label="text"
                                                :clearable="false"
                                                :reduce="(text) => text.value"
                                                placeholder="status"
                                                v-model="queryCondition.status"
                                                :options="statusOptions"
                                            />
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <v-date-picker v-model="dateRange" :model-config="modelConfig" timezone="UTC" is-range is24hr>
                                                <template v-slot="{ inputValue, togglePopover }">
                                                    <b-form-group>
                                                        <b-input-group v-on:click="togglePopover">
                                                            <b-input-group-prepend is-text>
                                                                <feather-icon icon="CalendarIcon" />
                                                            </b-input-group-prepend>
                                                            <b-form-input :value="dateToString(inputValue)" @keypress.prevent />
                                                        </b-input-group>
                                                    </b-form-group>
                                                </template>
                                            </v-date-picker>
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-1 mt-3">
                                        <b-col>
                                            <b-button variant="secondary" v-close-popper="true" @click="search"> Search</b-button>
                                            <b-button class="ml-2" variant="secondary" @click="clearQueryCondition"> Clear</b-button>
                                        </b-col>
                                    </b-row>
                                </template>
                            </VDropdown>
                        </b-col>
                    </b-row>

                    <b-overlay :show="table_show_overlay" rounded="sm">
                        <vue-good-table
                            ref="remote_q_table"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRows"
                            :columns="columns"
                            :rows="row_data"
                            :sort-options="{ enabled: false }"
                            :pagination-options="{
                                enabled: true,
                                mode: 'pages',
                                perPage: 10,
                                perPageDropdown: [5, 10, 20, 50, 100, 250, 500, 1000],
                                setCurrentPage: 1,
                            }"
                        >
                            <template slot="table-row" slot-scope="props">
                                <!-- Column: Common -->

                                <span v-if="props.column.field === 'created_unixtime'">
                                    {{ moment.unix(props.row[props.column.field]).utc().format("YYYY-MM-DD HH:mm:ss") }}
                                </span>

                                <span v-else-if="props.column.field === 'cryptomus_id'">
                                    <a :href="`https://pay.cryptomus.com/pay/${props.row[props.column.field]}`" target="_blank">{{
                                        props.row[props.column.field]
                                    }}</a>
                                </span>

                                <span v-else>
                                    {{ props.row[props.column.field] }}
                                </span>
                            </template>
                        </vue-good-table>
                    </b-overlay>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInvalidFeedback,
    BAlert,
    BForm,
    BFormFile,
    BFormTags,
    BFormTextarea,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";

import moment from "moment";
import VDatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
    components: {
        BCard,
        BCardFooter,
        BRow,
        BCol,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BButton,
        BOverlay,
        BBadge,
        BFormGroup,
        BFormInvalidFeedback,
        BAlert,
        BForm,
        BFormFile,
        BFormTags,
        BFormTextarea,
        VueGoodTable,
        vSelect,
        VDatePicker,
    },

    methods: {
        dateToString(inputValue) {
            return inputValue.start + " - " + inputValue.end;
        },
        clickAddNewDeposit() {
            this.newPrice = {};
        },

        clearQueryCondition() {
            this.queryCondition = {
                userId: null,
                status: null,
            };
            this.dateRange = {
                start: new Date(new Date().valueOf() - 3600 * 24 * 30 * 1000),
                end: new Date(),
            };
        },

        onPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.queryRecord();
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.queryRecord();
        },

        handleHide(bvModalEvt) {
            if (this.remote_updating) {
                bvModalEvt.preventDefault();
            }
        },

        async search() {
            this.$refs["remote_q_table"].reset();
            this.offset = 0;
            await this.queryRecord();
        },

        async queryRecord() {
            let userId = this.queryCondition.userId ? parseInt(this.queryCondition.userId) : null;
            let status = this.queryCondition.status;
            let startDate = moment(this.dateRange.start).utc().format("YYYY-MM-DD");
            let endDate = moment(this.dateRange.end).utc().format("YYYY-MM-DD");

            // console.log(userId)
            // console.log(startDate,endDate)

            this.table_show_overlay = true;
            // async function cryptomus_query(id, order_id, cryptomus_id, user_id, status, start_date, end_date, limit, offset, token)
            let resp = await this.$api.deposit.cryptomus_query(
                null,
                null,
                null,
                userId,
                status,
                startDate,
                endDate,
                this.limit,
                this.offset,
                this.$store.state.auth.my_web_token
            );
            this.table_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.row_data = resp.result.data;
            this.totalRows = resp.result.total_count;
        },
    },

    mounted() {
        this.queryRecord();
    },
    data() {
        return {
            moment: moment,
            //for table
            columns: [
                {
                    label: "Id",
                    field: "id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Date",
                    field: "created_unixtime",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "User id",
                    field: "user_id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Order id",
                    field: "order_id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Cryptomus id",
                    field: "cryptomus_id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Amount",
                    field: "amount",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Status",
                    field: "status",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Paid amount",
                    field: "paid_amount",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Payer currency",
                    field: "payer_currency",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Network",
                    field: "network",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
            ],
            row_data: [],
            limit: 10,
            offset: 0,
            totalRows: 0,

            table_show_overlay: false,
            add_deposit_show_overlay: false,

            queryCondition: {
                userId: null,
                status: null,
            },
            statusOptions: [
                { text: "pending", value: "pending" },
                { text: "success", value: "success" },
                { text: "failed", value: "failed" },
            ],

            dateRange: {
                start: new Date(new Date().valueOf() - 3600 * 24 * 30 * 1000),
                end: new Date(),
            },
            modelConfig: {
                start: {
                    timeAdjust: "00:00:00",
                },
                end: {
                    timeAdjust: "00:00:00",
                },
            },
        };
    },
};
</script>
